import { Route, Switch } from "wouter";
import "./App.scss";
import PrintPage from "./components/PrintPage";
import Spellbook from "./components/Spellbook";
import SpellPage from "./components/SpellPage";
import SpellSelection from "./components/SpellSelection";

function App() {
  return (
    <Switch>
      <Route path="/" component={SpellSelection} />
      <Route path="/print" component={PrintPage} />
      <Route path="/spell/:name" component={SpellPage} />
      <Route path="/spellbook" component={Spellbook} />
    </Switch>
  );
}

export default App;
