import React from "react";
import { SPELLS_KEY } from "../const";
import Spell from "./Spell";

function PrintPage() {
  const spells = JSON.parse(localStorage.getItem(SPELLS_KEY)) || [];

  return (
    <div>
      {spells
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .sort((a, b) => a.level - b.level)
        .map((spell) => (
          <Spell key={spell.name} spell={spell} />
        ))}
    </div>
  );
}

export default PrintPage;
