import React, { useEffect, useState } from "react";
import { Link } from "wouter";
import { SETTINGS_KEY, SPELLS_KEY } from "../const";
import spells from "../spells.json";
import "./SpellSelection.scss";

const classes = [
  { shortName: "artificer", fullName: "Artefice" },
  { shortName: "bard", fullName: "Bardo" },
  { shortName: "wizard", fullName: "Mago" },
  { shortName: "sorcerer", fullName: "Stregone" },
  { shortName: "warlock", fullName: "Warlock" },
  { shortName: "cleric", fullName: "Chierico" },
  { shortName: "paladin", fullName: "Paladino" },
  { shortName: "ranger", fullName: "Ranger" },
  { shortName: "druid", fullName: "Druido" },
];

function SpellSelection() {
  const [expandedFilters, setExpandedFilters] = useState(false);

  const [expandedSelection, setExpandedSelection] = useState(false);

  const [filtered, setFiltered] = useState(
    spells
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .sort((a, b) => a.level - b.level)
  );
  const [selected, setSelected] = useState(
    JSON.parse(localStorage.getItem(SPELLS_KEY)) || []
  );

  const settings = JSON.parse(localStorage.getItem(SETTINGS_KEY)) || {};

  const select = (spell) => {
    const list = (() => {
      if (selected.find(({ name }) => name === spell.name)) {
        return selected.filter(({ name }) => name !== spell.name);
      }

      return [...selected, spell];
    })();

    updateSelection(list);
  };

  const updateSelection = (list) => {
    setSelected(list);
    localStorage.setItem(SPELLS_KEY, JSON.stringify(list));
  };

  const levels = Array.from({ length: 10 }).map((_, index) => index);

  const books = spells
    .map(({ books }) => books)
    .flat()
    .reduce((deduped, book) => {
      const present = deduped.some(
        ({ shortName }) => shortName === book.shortName
      );

      if (present) return deduped;

      return [...deduped, book];
    }, [])
    .sort((a, b) => (a.fullName < b.fullName ? -1 : 1));

  const [search, setSearch] = useState("");
  const [selectedBooks, setSelectedBooks] = useState(
    settings.books || books.map(({ shortName }) => shortName)
  );
  const [selectedClasses, setSelectedClasses] = useState(
    settings.classes || classes.map(({ shortName }) => shortName)
  );
  const [selectedLevels, setSelectedLevels] = useState(
    settings.levels || levels
  );

  const [selectedRitual, setRitual] = useState(settings.ritual || true);
  const [concentration, setConcentration] = useState(
    settings.concentration || true
  );

  const updateFilter = (current, array, setFunction) => {
    if (array.includes(current)) {
      setFunction(array.filter((item) => current !== item));
      return;
    }

    setFunction([...array, current]);
  };

  useEffect(() => {
    localStorage.setItem(
      SETTINGS_KEY,
      JSON.stringify({
        ritual: selectedRitual,
        concentration,
        books: selectedBooks,
        classes: selectedClasses,
        levels: selectedLevels,
      })
    );

    const filtered = spells
      .filter(({ name, original, books, level, classes, ritual, duration }) => {
        const matchesSearch =
          name.toLowerCase().includes(search) ||
          original.toLowerCase().includes(search);

        const matchesBooks = books.some(({ shortName }) =>
          selectedBooks.includes(shortName)
        );

        const matchesClasses = classes.some((shortName) =>
          selectedClasses.includes(shortName)
        );

        const matchesLevels = selectedLevels.includes(level);

        const matchesRitual = !selectedRitual ? ritual === false : true;
        const matchesConcentration = !concentration
          ? !duration.toLowerCase().includes("concentrazione")
          : true;

        return (
          matchesSearch &&
          matchesBooks &&
          matchesLevels &&
          matchesClasses &&
          matchesRitual &&
          matchesConcentration
        );
      })
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .sort((a, b) => a.level - b.level);

    setFiltered(filtered);
  }, [
    search,
    selectedBooks,
    selectedClasses,
    selectedLevels,
    setFiltered,
    selectedRitual,
    concentration,
  ]);

  return (
    <div className="spell-selection">
      <div className="mobile">
        <button
          className="filters-button"
          onClick={() => {
            if (!expandedFilters) window.scrollTo(0, 0);
            setExpandedFilters(!expandedFilters);
          }}
        >
          {expandedFilters ? "Nascondi" : "Mostra"} Filtri
        </button>

        <button
          className="selected-button"
          onClick={() => {
            if (!expandedSelection) window.scrollTo(0, 0);
            setExpandedSelection(!expandedSelection);
          }}
        >
          {expandedSelection ? "Nascondi" : "Mostra"} Selezionati
        </button>

        <div className="print-link">
          <Link href="/print">Stampa Selezionati</Link>
        </div>

        <div>
          <Link
            href={`/spellbook?spells=${selected
              .map(({ uuid }) => uuid)
              .join("+")}`}
          >
            Vedi Grimorio
          </Link>
        </div>
      </div>
      <div className="grid">
        <div className="spells">
          {filtered.map((spell) => {
            const { uuid, name, original, duration, ritual } = spell;
            return (
              <div key={uuid}>
                <label>
                  <input
                    type="checkbox"
                    checked={
                      selected.find((item) => item.name === name) || false
                    }
                    onChange={() => select(spell)}
                  />
                  {name} ({original}){" "}
                  {duration.toLowerCase().includes("concentrazione")
                    ? " 🧘"
                    : ""}
                  {ritual ? " 🤲" : ""}
                </label>
                <Link href={`/spell/${name}`}>📄</Link>
              </div>
            );
          })}
        </div>
        <div className="selected">
          <div
            className={`filters ${expandedFilters ? "expanded" : "collapsed"}`}
          >
            <p>🧘 Concentrazione</p>
            <p>🤲 Rituale</p>
          </div>

          <div
            className={`filters ${expandedFilters ? "expanded" : "collapsed"}`}
          >
            <input
              placeholder="Ricerca..."
              type="search"
              onChange={({ target }) => {
                const search = target.value;
                setSearch(search);
              }}
              value={search}
            />
          </div>

          <div
            className={`filters ${expandedFilters ? "expanded" : "collapsed"}`}
          >
            <div className="classes">
              <h1>Classi</h1>
              <div className="buttons">
                <button
                  onClick={() =>
                    setSelectedClasses(
                      classes.map(({ shortName }) => shortName)
                    )
                  }
                >
                  Tutti
                </button>
                <button onClick={() => setSelectedClasses([])}>Nessuno</button>
              </div>
              {classes.map(({ shortName, fullName }) => (
                <label key={shortName}>
                  <input
                    type="checkbox"
                    value={shortName}
                    checked={selectedClasses.includes(shortName)}
                    onChange={() =>
                      updateFilter(
                        shortName,
                        selectedClasses,
                        setSelectedClasses
                      )
                    }
                  />
                  {fullName}
                </label>
              ))}
            </div>

            <div className="levels">
              <h1>Livelli</h1>
              <div className="buttons">
                <button onClick={() => setSelectedLevels([...levels])}>
                  Tutti
                </button>
                <button onClick={() => setSelectedLevels([])}>Nessuno</button>
              </div>
              {levels.map((level) => (
                <label key={level}>
                  <input
                    type="checkbox"
                    value={level}
                    checked={selectedLevels.includes(level)}
                    onChange={() =>
                      updateFilter(level, selectedLevels, setSelectedLevels)
                    }
                  />
                  {level ? `Livello ${level}` : "Trucchetti"}
                </label>
              ))}
            </div>

            <div className="books">
              <h1>Manuali</h1>
              <div className="buttons">
                <button
                  onClick={() =>
                    setSelectedBooks(books.map(({ shortName }) => shortName))
                  }
                >
                  Tutti
                </button>
                <button onClick={() => setSelectedBooks([])}>Nessuno</button>
              </div>
              {books.map(({ shortName, fullName }) => (
                <label key={shortName}>
                  <input
                    type="checkbox"
                    value={shortName}
                    checked={selectedBooks.includes(shortName)}
                    onChange={() =>
                      updateFilter(shortName, selectedBooks, setSelectedBooks)
                    }
                  />
                  {fullName}
                </label>
              ))}
            </div>

            <div className="other">
              <h1>Altro</h1>
              <label>
                <input
                  type="checkbox"
                  value={selectedRitual}
                  checked={selectedRitual}
                  onChange={() => setRitual(!selectedRitual)}
                />
                Rituale
              </label>

              <label>
                <input
                  type="checkbox"
                  value={concentration}
                  checked={concentration}
                  onChange={() => setConcentration(!concentration)}
                />
                Concentrazione
              </label>
            </div>
          </div>

          <div className={expandedSelection ? "expanded" : "collapsed"}>
            <button onClick={() => updateSelection([])}>
              Deseleziona tutti
            </button>
            <div className="selected-spells">
              {selected.map((spell) => {
                const { name, original } = spell;
                return (
                  <div key={name}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selected.find((item) => item.name === name)}
                        onChange={() => select(spell)}
                      />
                      {name} ({original})
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="print-link">
              <Link href="/print">Stampa</Link>
            </div>
            <div>
              <Link
                href={`/spellbook?spells=${selected
                  .map(({ uuid }) => uuid)
                  .join("+")}`}
              >
                Vedi Grimorio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpellSelection;
