import React from "react";
import "./Spell.scss";

function Spell({ spell, expanded = true, expand = false }) {
  const {
    name,
    original,
    school,
    level,
    castingTime,
    range,
    components,
    materials,
    duration,
    higherLevels,
    description,
    ritual,
  } = spell;

  return (
    <div className="card">
      <h3
        className="name"
        onClick={expand ? expand : () => {}}
        style={expand ? { cursor: "pointer" } : {}}
      >
        {name} ({original})
      </h3>
      <div className={expanded ? "expanded" : "collapsed"}>
        <small className="school-level">
          {!level && "Trucchetto di "}
          {school}
          {level ? ` di ${level}° Livello ` : ""}
          {ritual && <span>(rituale)</span>}
        </small>
        <div className="casting-time">
          <b>Tempo di lancio: </b>
          {castingTime}
        </div>
        <div className="range">
          <b>Gittata: </b>
          {range}
        </div>
        <div className="components">
          <b>Componenti: </b>
          {components} {materials && <span>({materials})</span>}
        </div>
        <div className="duration">
          <b>Durata: </b>
          {duration}
        </div>
        <div className="card-body">
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: description.reduce(
                (text, paragraph) => text + `<br/>${paragraph}`,
                ""
              ),
            }}
          />

          {higherLevels && (
            <div
              className="higher-levels"
              dangerouslySetInnerHTML={{ __html: higherLevels }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Spell;
