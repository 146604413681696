import React, { useState } from "react";
import allSpells from "../spells.json";
import Spell from "./Spell";
import "./Spellbook.scss";

function Spellbook() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { spells = "" } = Object.fromEntries(urlSearchParams.entries());

  const selected = allSpells.filter((spell) => spells.includes(spell.uuid));

  const [cantrips, ...levels] = Array.from({ length: 10 }, (_, index) => {
    return selected.filter(({ level }) => level === index);
  });

  const [expanded, setExpanded] = useState([]);

  const expand = (uuid) => {
    if (expanded.includes(uuid)) {
      setExpanded(expanded.filter((item) => item !== uuid));
      return;
    }

    setExpanded([...expanded, uuid]);
  };

  return (
    <section>
      <article>
        <h1 className="spellbook-toggle">Trucchetti ({cantrips.length})</h1>
        <div className="spells">
          {cantrips.map((spell) => (
            <Spell
              key={spell.name}
              spell={spell}
              expanded={expanded.includes(spell.uuid)}
              expand={() => expand(spell.uuid)}
            />
          ))}
        </div>
      </article>

      {levels
        .filter((level) => level.length)
        .map((level, index) => (
          <article key={`level-${index}`}>
            <h1 className="spellbook-toggle">
              Livello {level[0].level} ({level.length})
            </h1>
            <div className={"spells"}>
              {level.map((spell) => (
                <Spell
                  key={spell.name}
                  spell={spell}
                  expanded={expanded.includes(spell.uuid)}
                  expand={() => expand(spell.uuid)}
                />
              ))}
            </div>
          </article>
        ))}
    </section>
  );
}

export default Spellbook;
