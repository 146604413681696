import React from "react";
import { useRoute } from "wouter";
import spells from "../spells.json";
import Spell from "./Spell";

function SpellPage() {
  // eslint-disable-next-line
  const [_, params] = useRoute("/spell/:name");

  const spell = spells.find(
    (spell) => encodeURIComponent(spell.name) === params.name
  );

  return <Spell spell={spell} />;
}

export default SpellPage;
